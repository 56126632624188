<template>
	<div class="news">
		<container :gutter="20">
			<div class="main">
				<!-- <el-carousel :interval="2000" arrow="always">
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/96ce54"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/f05654"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>

          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/1bd1a5"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel> -->
				<div class="news-list">
					<div class="new-box" v-for="i in 10" :key="i">
						<div class="poster">
							<img
								src="https://dummyimage.com/220x150/70f3ff"
								alt=""
								srcset=""
							/>
						</div>

						<div class="desc">
							<div class="title">
								2020年销售额突破1亿元，2021年前8月公司营收超过8000万，同比增长57%。
							</div>
							<div class="desc-text">
								2020年销售额突破1亿元，2021年前8月公司营收超过8000万，同比增长57%。
							</div>
							<div class="bottom">
								<div>
									来自主题：
									<span class="link">手游+电竞=下一个风口？</span>
									<span class="vline">|</span>
									娱刺儿
								</div>
								<div>
									<i class="el-icon-time"></i>
									5分钟前
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="more-btn">
					<el-button>查看更多企业</el-button>
				</div>
			</div>
			<div slot="side">
				<div class="co-list">
					<div class="title">
						<span>推荐企业</span>
						<span>
							<i class="el-icon-refresh"></i>
							换一换</span
						>
					</div>

					<div class="co-box" v-for="i in 8" :key="i">
						<div class="logo">
              <div class="vip"></div>
							<img src="https://dummyimage.com/64x64/7fecad" alt="" srcset="" />
						</div>
						<div class="desc">
							<div class="desc-title">咚咚来客</div>
							<div class="desc-text">做门店私域流量经营第一服务商</div>
						</div>
					</div>

					<div class="more-btn">
						<el-button style="width: 100%">查看更多企业</el-button>
					</div>
				</div>
			</div>
		</container>
	</div>
</template>

<script>
import Container from './Container.vue'
export default {
	components: { Container },
}
</script>

<style lang="scss" scoped>
.news {
	padding: 20px 0;

	.news-list {
		.new-box {
			position: relative;
			padding: 15px 0;
			display: flex;

			.poster {
				flex: 0 0 auto;
				width: 220px;
				height: 150px;
				overflow: hidden;
				margin-right: 10px;
				margin-left: 10px;
			}

			.desc {
				position: relative;
				flex: 1 1 auto;
				.title,
				.desc-text {
					font-size: 18px;
					color: #262626;
					text-align: justify;
					margin-top: 8px;
					max-height: 48px;
					line-height: 24px;
					max-width: 640px;
					word-break: break-all;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}

				.desc-text {
					font-size: 14px;
					color: #999;
				}

				.bottom {
					color: #999;
					font-size: 12px;
					display: flex;
					justify-content: space-between;
					padding-right: 20px;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;

					.link {
						color: #4285f4;
					}

					.vline {
						margin: 0 4px;
					}
				}
			}
		}
		.new-box:hover {
			transition: all 0.2s linear;
			border-radius: 2px;
			box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
		}
	}

	.co-list {
		.title {
			display: flex;
			justify-content: space-between;

			span:nth-child(1) {
				font-size: 20px;
				color: #262626;
				padding-left: 4px;
			}

			span:nth-child(2) {
				font-size: 13px;
				color: #787878;
				position: relative;
				float: right;
				display: block;
				padding-right: 10px;
				cursor: pointer;
			}
		}

		.co-box {
			position: relative;
			cursor: pointer;
			padding: 12px 0;
			display: flex;

			justify-content: space-between;
			border: 1px solid transparent;

			&:hover,
			&:active {
				background: #fff;
				border: 1px solid #ededed;
			}

			.logo {
				flex: 0 0 auto;
				width: 64px;
				height: 64px;
				border-radius: 64px;
        position: relative;
        img{
        border-radius: 64px;
        }
        .vip{
          display: inline-block;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 20px;
          z-index: 999;
          height: 20px;
          background: url('~@/assets/images/v.png') no-repeat;
          background-size: cover;
        }
			}

			.desc {
				flex: 1 1 auto;
				margin-left: 10px;

				.desc-title {
					margin-top: 8px;
					font-size: 14px;
					color: #262626;
					display: block;
					line-height: 16px;
					height: 16px;
					margin-bottom: 6px;
					cursor: pointer;

					max-width: 200px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.desc-text {
					cursor: pointer;
					font-size: 13px;
					line-height: 18px;
					color: #787878;

					word-break: break-all;
					overflow: hidden;
					text-overflow: ellipsis;

					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}
			}
		}
	}

	.more-btn {
		padding: 20px 0;
		text-align: center;
	}
}
</style>
